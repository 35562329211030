import React from "react";
import Lottie from 'lottie-react';
import content from '../dashboard/lotties/content.json';
import './style.css'
const AdminDashboard = () => {
    return <div>
        <Lottie className="content" animationData={content} loop={true} />
    </div>;
};

export default AdminDashboard;
