import React, { useState } from 'react'
import './caursel.css';
import { BiSolidUser, BiSolidTimeFive } from "react-icons/bi";
import mb from "../../images/bg-vuong.png";


import { WORKData } from '../../utilities/WORKData';
const Caursel = () => {
    const [visibleItems, setVisibleItems] = useState(4);
    const loadMore = () => {
        setVisibleItems(visibleItems + 4);
    };

    return (
        <div>
            <section className='bg-[#242424] hidden lg:block'>
                <div className="container">
                    <div className="brands">
                        <div className="brands__preWrapper">
                            <div className="brands__wrapper">
                                {WORKData.map((item, index) => (
                                    <a href="/#" key={index}>
                                        <div
                                            className="bg-word max-w-[527px] h-[161px] bg-no-repeat bg-contain flex items-center gap-5 p-[20px]">
                                            <div className="w-[25%]">
                                                <img className="w-full" src={item.avatar} alt="word1" />
                                            </div>
                                            <div className="">
                                                <div className="text-[#fff] text-[22px] font-semibold">
                                                    <h5 className=' max-w-[350px] truncate overflow-ellipsis test-css' title={item.title} >
                                                        {item.title}
                                                    </h5>
                                                </div>
                                                <div className="flex justify-between items-center gap-5 py-4">
                                                    <h6 className="text-[#FFA91E] text-[23.5px] font-light flex items-center gap-2 "><BiSolidUser /> {item.people}</h6>
                                                    <h6 className="text-[#FFA91E] text-[23.5px] font-light flex items-center gap-2"><BiSolidTimeFive />Toàn thời gian</h6>
                                                </div>
                                                <div className="text-[23.5px] font-extrabold text-[#000] flex justify-end pt-2 pl-12">
                                                    <p>{item.salary}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="block lg:hidden px-2 py-2">
                <div className='grid grid-cols-2 md:grid-cols-4 gap-3'>
                    {WORKData.slice(0, visibleItems).map((item, index) => (
                        <div key={index} className="relative">
                            <img src={mb} alt="mb" />
                            <div className="absolute top-2 w-full text-[#fff] text-[22px]  text-center font-semibold">
                                <h5>{item.titleMB}</h5>
                            </div>
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[40%] mx-auto">
                                <img className="w-full" src={item.avatar} alt="word1" />
                            </div>
                            <div className="absolute  bottom-0 text-[20.5px] md:text-[19.5px] font-extrabold text-[#000] flex justify-end pt-2 pl-12">
                                <p>{item.salaryMB}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='text-center py-2 m-2'>
                    {visibleItems < WORKData.length && (
                        <button className='border-[1px] p-2 rounded-md border-[#333] hover:bg-[#f90] hover:text-[#fff]' onClick={loadMore}>Xem thêm</button>
                    )}
                </div>
            </section>
        </div>
    )
}

export default Caursel