import React from 'react'
import './profile.css';
import iconAv from '../../images/Accept.png'
import link from '../../images/link-circle.png'
import tele from '../../images/icon-tele.png'
import zalo from '../../images/icon-zalo.png'
import facebook from '../../images/icon-facebook.png'
import youtube from '../../images/icon-youtube.png'
import tiktok from '../../images/icon-tiktok.png'
import gmail from '../../images/icon-gmail.png'
const Profile = ({ hrData }) => {
    return (
        <div className='bg-profile px-2'>
            <div className='max-w-[1200px] mx-auto py-2 lg:py-[44px]'>
                <div className='flex flex-col lg:flex-row justify-center items-center gap-[20px] xl:gap-[155px]'>
                    <div className=''>
                        <div className='relative px-2'>
                            <div className=''>
                                <img className='avatar w-[477px] h-[400px] md:h-[477px]' src={`${process.env.REACT_APP_IMAGE_DOMAIN}${hrData?.avatar}`} alt="Avatar" />
                            </div>
                            <div className='absolute bottom-0 w-full name rounded-[50px] border-[2px] border-[#fff] text-center'>
                                <h3 className='text-[20px] md:text-[28px] text-[#fff] font-black'>{hrData?.fullName}</h3>
                                <h5 className='text-[20px] md:text-[25px] text-[#fff] font-medium'>{hrData?.subName}</h5>
                            </div>
                            <div className='absolute bottom-[70px] md:bottom-[100px] right-[5px] md:right-[5px]'>
                                <img src={iconAv} alt="icon" />
                            </div>
                        </div>
                        <div className='text text-center w-[410px] mx-auto break-words py-2 '>
                            <h6 className='text-[18px] md:text-[21px] font-extrabold'>{hrData?.bio}</h6>
                        </div>
                    </div>
                    <div className='w-full md:w-[80%] lg:w-[40%]'>
                        <a href={hrData?.telegramLink} className='tele flex justify-between items-center p-1 px-2' >
                            <div>
                                <img src={tele} alt="tele" />
                            </div>
                            <div className='text-[23px] xl:text-[23px] font-bold text-[#fff]'>
                                <h5>{hrData?.telegramName}</h5>
                            </div>
                            <div>
                                <img src={link} alt="link" />
                            </div>
                        </a>
                        <a href={hrData?.zaloLink} className='zalo flex justify-between items-center p-1 px-2 mt-[10px]' >
                            <div>
                                <img src={zalo} alt="zalo" />
                            </div>
                            <div className='text-[23px] xl:text-[23px] font-bold text-[#fff]'>
                                <h5>{hrData?.zaloName}</h5>
                            </div>
                            <div>
                                <img src={link} alt="link" />
                            </div>
                        </a>
                        <a href={hrData?.facebookLink} className='facebook flex justify-between items-center p-1 px-2 mt-[10px]' >
                            <div>
                                <img src={facebook} alt="facebook" />
                            </div>
                            <div className='text-[17px] xl:text-[23px] font-bold text-[#fff]'>
                                <h5>{hrData?.facebookName}</h5>
                            </div>
                            <div>
                                <img src={link} alt="link" />
                            </div>
                        </a>
                        <a href={hrData?.youtubeLink} className='youtube flex justify-between items-center p-1 px-2 mt-[10px]' >
                            <div>
                                <img src={youtube} alt="youtube" />
                            </div>
                            <div className='text-[17px] xl:text-[23px] font-bold text-[#fff]'>
                                <h5>{hrData?.youtubeName}</h5>
                            </div>
                            <div>
                                <img src={link} alt="link" />
                            </div>
                        </a>
                        <a href={hrData?.tiktokLink} className='tiktok flex justify-between items-center p-1 px-2 mt-[10px]' >
                            <div>
                                <img src={tiktok} alt="tiktok" />
                            </div>
                            <div className='text-[23px] xl:text-[23px] font-bold text-[#fff]'>
                                <h5>{hrData?.tiktokName}</h5>
                            </div>
                            <div>
                                <img src={link} alt="link" />
                            </div>
                        </a>
                        <a href={hrData?.gmailLink} className='gmail flex justify-between items-center p-1 px-2 mt-[10px]' >
                            <div>
                                <img src={gmail} alt="gmail" />
                            </div>
                            <div className='text-[23px] xl:text-[23px] font-bold text-[#fff]'>
                                <h5>{hrData?.gmailName}</h5>
                            </div>
                            <div>
                                <img src={link} alt="link" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile