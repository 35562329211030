import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error from "./components/Error";
import Layout from "./components/Layouts/main";
import Home from "./pages/home";
import ProtectedRoute from "./router/protectedRoute";
import Login from "../src/pages/admin/login";
import AdminLayout from "./components/Layouts/admin";
import AdminDashboard from "./pages/admin/dashboard";
import AdminUser from "./pages/admin/user";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={
						<Home />
					} />
					<Route path="/:userCode" element={
						<Home />
					} />
				</Route>
				<Route path="admin" element={<AdminLayout />}>
					<Route index element={
						<ProtectedRoute>
							<AdminDashboard />
						</ProtectedRoute>
					} />
				</Route>
				<Route path="user" element={<AdminLayout />}>
					<Route index element={
						<ProtectedRoute>
							<AdminUser />
						</ProtectedRoute>
					} />
				</Route>
				<Route path="admin/login" element={
					<Login />
				} />
				<Route path="*" element={<Error />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
