import React, { useEffect } from 'react'
import './content.css'
import video from '../../images/video.png'
import b1 from '../../images/buoc1.png'
import b2 from '../../images/buoc2.png'
import b3 from '../../images/buoc3.png'
import b4 from '../../images/buoc4.png'
import b5 from '../../images/buoc5.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import modal from '../../images/icon-modal.png';
import girl from '../../images/girl.png';
import daotao from '../../images/icon-daotao.png';
import moitruong from '../../images/icon-moitruong.png';
import chinhsach from '../../images/icon-chinhsach.png';
import uytin from '../../images/icon-uytin.png';
import logo789 from '../../images/logo789.png';
import qr789 from '../../images/qr789.png';
import link789 from '../../images/Linkweb789.png';
import phone789 from '../../images/phone-789.png';
import logojun88 from '../../images/logo-jun88.png';
import qrjun88 from '../../images/qrjun88.png';
import linkjun88 from '../../images/Linkjun88.png';
import phonejun88 from '../../images/phonejun88.png';
import logonew88 from '../../images/logonew88.png';
import qrnew88 from '../../images/qrnew88.png';
import linknew88 from '../../images/linknew88.png';
import phonenew88 from '../../images/phonenew88.png';
import logoshbet from '../../images/logoshbet.png';
import qrshbet from '../../images/qrsh.png';
import linkshbet from '../../images/Linkshbet.png';
import phoneshbet from '../../images/phoneshbet.png';
import logof8 from '../../images/logof8.png';
import qrf8 from '../../images/qrf8.png';
import linkf8 from '../../images/Linkf8.png';
import phonef8 from '../../images/phonef8.png';
import logohi88 from '../../images/logohi88.png';
import qrhi88 from '../../images/qrhi88.png';
import linkhi88 from '../../images/linkhi88.png';
import phonehi88 from '../../images/phonehi88.png';
import logomocbai from '../../images/logomocbai.png';
import qrmocbai from '../../images/qrmocbai.png';
import linkmocbai from '../../images/linkmocbai.png';
import phonemocbai from '../../images/phonemocbai.png'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { modalData } from '../../utilities/CSNS';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { Autoplay, Pagination } from 'swiper/modules';


const Card = () => {
    const [open, setOpen] = React.useState(false);
    const [modalCotent, setModalContent] = React.useState();
    const [modalTitle, setModalTitle] = React.useState();

    useEffect(() => {
        AOS.init();
    }
        , [])
    const handleOpen = (order) => {
        const m = modalData.find(x => x.order === order);
        console.log(order)
        setModalTitle(m.title);
        setModalContent(m.content);
        setOpen(true);
    };
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    var menu = ['789BET', 'Jun88', 'NEW88', 'SHBET', 'F8BET', 'Hi88', 'Mocbai']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span style=" color: #000; font-size: 20px; font-weight: 500;" class="' + className + '">' + (menu[index]) + '</span>';
        },
    };
    return (
        <div className='bg'>
            <div className='max-w-[1200px] mx-auto'>
                <div>
                    <div className='max-w-[1200px] mx-auto py-3 md:py-[50px]'>
                        <div className='block md:hidden'>
                            <h2 className='text-[#000] text-[25px] font-semibold text-center'><b className='text-[30px]'>OKVIP</b><br /> CHÚNG TÔI LÀ AI?</h2>
                            <p className='text-center pb-3 font-normal text-[15px]'>OKVIP là một trong những tập đoàn phát triển Game online hàng đầu châu Á.
                                Hiện nay, OKVIP phát triển và chiếm thị phần lớn mảng game bài tại các nước như Campuchia,
                                Dubai, Philippines và Singapore. Với trụ sở chính ở Mộc bài – Campuchia với hàng chục ngàn nhân sự.
                                OKVIP tuyển dụng việc làm Campuchia với nhiều vị trí.</p>
                        </div>
                        <video data-aos="fade-up" controls muted loop poster={video}>
                            <source src="https://api.okvip.media/files/storage/okvip-videos/okvip-site.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div>
                    <div>
                        <h3 className='text-[#000] text-[30px] md:text-[41px] pt-2 font-bold text-center'>CÁC TRANG LIÊN MINH</h3>
                    </div>
                    <Swiper
                        pagination={pagination}
                        modules={[Autoplay, Pagination]}
                        spaceBetween={30}
                        navigation={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        className="mySwiper " data-aos="zoom-in-up" data-aos-duration="2000"
                    >
                        <SwiperSlide>
                            <div className='flex flex-col-reverse lg:flex-row justify-center items-center pt-5'>
                                <div className='w-full lg:w-[50%]'>
                                    <div className='flex justify-between items-center gap-4'>
                                        <div>
                                            <img src={logo789} alt="logo789" />
                                        </div>
                                        <div className='qr px-5 md:px-0'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 px-2 md:px-10'>
                                                <img src={qr789} alt="qr789" />
                                            </div>
                                            <div className='py-5 '>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>SCAN MÃ QR ĐỂ TẢI APP</p>
                                            </div>
                                        </div>
                                        <div className='link'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 py-[30px] md:py-[68px] lg:py-[42px] xl:py-[62px]'>
                                                <img src={link789} alt="link789" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>TRUY CẬP LINK VÀO WEB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-[#535353] text-[16.5px] font-normal text-justify pt-[38px]'>
                                        <p>
                                            <b>789BET</b> là nhà cái có giấy phép cá cược trực tuyến hợp pháp được cấp
                                            thông qua Khu kinh tế Isle of Man & Cagayan Economic Zone and Free Port.
                                            Đây là tổ chức quốc tế phát triển với quy mô trải dài khắp khu vực Châu Á
                                            - Thái Bình Dương. Với kinh nghiệm và độ uy tín cao đã phục vụ hơn 10 triệu
                                            người chơi, 789BET đã và đang khẳng định vị thế cùng thương hiệu của mình trên
                                            thị trường game online. Là một nhà cái trực thuộc tập đoàn M.A.N Entertainment,
                                            có trụ sở chính đặt tại thủ đô Manila, Philippines - tập đoàn được...
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={phone789} alt="phone789" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex flex-col-reverse lg:flex-row justify-center items-center pt-5'>
                                <div className='w-full lg:w-[50%]'>
                                    <div className='flex justify-between items-center gap-4'>
                                        <div>
                                            <img src={logojun88} alt="logojun88" />
                                        </div>
                                        <div className='qr px-5 md:px-0'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 px-2 md:px-10'>
                                                <img src={qrjun88} alt="qrjun88" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>SCAN MÃ QR ĐỂ TẢI APP</p>
                                            </div>
                                        </div>
                                        <div className='link'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 py-[30px] md:py-[68px] lg:py-[42px] xl:py-[62px]'>
                                                <img src={linkjun88} alt="linkjun88" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>TRUY CẬP LINK VÀO WEB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-[#535353] text-[16.5px] font-normal text-justify pt-[38px]'>
                                        <p>
                                            <b>Jun88</b> – Một trong những công ty được đăng ký hợp pháp của Costa Rica
                                            và được chính cơ quan này công nhận là một nhà cái uy tín, chất lượng và
                                            đáng tin cậy nhất. Mục tiêu của Jun88 là tạo ra một sân chơi giải trí chất
                                            lượng cao,đa dạng nhất thị trường cá cược trực tuyến. Mang lại cho người chơi
                                            những sự lựa chọn hợp lý, thú vị cùng dịch vụ chăm sóc khách hàng nhanh chóng,
                                            tiện lợi và tận tình nhất. Trong thị trường trò chơi trực tuyến ngày càng sôi
                                            động và lớn mạnh, ngoài các trò chơi, an ninh mạng...
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={phonejun88} alt="phonejun88" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex flex-col-reverse lg:flex-row justify-center items-center pt-5'>
                                <div className='w-full lg:w-[50%]'>
                                    <div className='flex justify-between items-center gap-4'>
                                        <div>
                                            <img src={logonew88} alt="logonew88" />
                                        </div>
                                        <div className='qr px-5 md:px-0'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 px-2 md:px-10'>
                                                <img src={qrnew88} alt="qrnew88" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>SCAN MÃ QR ĐỂ TẢI APP</p>
                                            </div>
                                        </div>
                                        <div className='link'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 py-[30px] md:py-[68px] lg:py-[42px] xl:py-[62px]'>
                                                <img src={linknew88} alt="linknew88" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>TRUY CẬP LINK VÀO WEB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-[#535353] text-[16.5px] font-normal text-justify pt-[38px]'>
                                        <p>
                                            <b>NEW88.COM</b> –là một công ty giải trí được đăng ký hợp pháp ở Costa Rica,
                                            tất cả các hoạt động kinh doanh cá cược đều tuân theo hiệp ước cá cược của chính
                                            phủ Costa Rica. Trong thị trường cá cược trực tuyến ngày càng sôi nổi và được
                                            đông đảo người chơi lựa chọn, chúng tôi không ngừng tìm kiếm những đổi mới và thay
                                            đổi, và chúng tôi phát triển các phương pháp trò chơi khác nhau với đội ngũ sáng
                                            tạo đáng tự hào của chúng tôi. Cung cấp cho khách hàng nhiều sự lựa chọn, lĩnh vực
                                            chính của công ty NEW88.COM...
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={phonenew88} alt="phonenew88" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex flex-col-reverse lg:flex-row justify-center items-center pt-5'>
                                <div className='w-full lg:w-[50%]'>
                                    <div className='flex justify-between items-center gap-4'>
                                        <div>
                                            <img src={logoshbet} alt="logoshbet" />
                                        </div>
                                        <div className='qr px-5 md:px-0'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 px-2 md:px-10'>
                                                <img src={qrshbet} alt="qrshbet" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>SCAN MÃ QR ĐỂ TẢI APP</p>
                                            </div>
                                        </div>
                                        <div className='link'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 py-[30px] md:py-[68px] lg:py-[42px] xl:py-[62px]'>
                                                <img src={linkshbet} alt="linkshbet" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>TRUY CẬP LINK VÀO WEB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-[#535353] text-[16.5px] font-normal text-justify pt-[38px]'>
                                        <p>
                                            <b>SHBET</b> –là một công ty giải trí được đăng ký hợp pháp ở Costa Rica,
                                            tất cả các hoạt động kinh doanh cá cược đều tuân theo hiệp ước cá cược của
                                            chính phủ Costa Rica. Trong thị trường cá cược trực tuyến ngày càng sôi nổi
                                            và được đông đảo người chơi lựa chọn, chúng tôi không ngừng tìm kiếm những
                                            đổi mới và thay đổi, và chúng tôi phát triển các phương pháp trò chơi khác
                                            nhau với đội ngũ sáng tạo đáng tự hào của chúng tôi. Cung cấp cho khách hàng
                                            nhiều sự lựa chọn, lĩnh vực chính của công ty SHBET.COM chúng tôi...
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={phoneshbet} alt="phoneshbet" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex flex-col-reverse lg:flex-row justify-center items-center pt-5'>
                                <div className='w-full lg:w-[50%]'>
                                    <div className='flex justify-between items-center gap-4'>
                                        <div>
                                            <img src={logof8} alt="logof8" />
                                        </div>
                                        <div className='qr px-5 md:px-0'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 px-2 md:px-10'>
                                                <img src={qrf8} alt="qrf8" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>SCAN MÃ QR ĐỂ TẢI APP</p>
                                            </div>
                                        </div>
                                        <div className='link'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 py-[30px] md:py-[68px] lg:py-[42px] xl:py-[62px]'>
                                                <img src={linkf8} alt="linkf8" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>TRUY CẬP LINK VÀO WEB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-[#535353] text-[16.5px] font-normal text-justify pt-[38px]'>
                                        <p>
                                            <b>F8BET.COM</b> – là công ty cá cược uy tín thuộc tập đoàn M.A.N Entertainment,
                                            có trụ sở chính tại Philippines, và được cấp phép hợp pháp thông qua Khu kinh tế
                                            Isle of Man & Cagayan Economic Zone and Free Port. Hiện nay, Nhà cái cá cược đang
                                            phủ sóng trên khắp thị trường cá cược trực tuyến không thể nào không kể đến cái
                                            tên F8BET.COM . Việc mang đến cho người chơi những phút giây thư giãn và vui vẻ
                                            chính là điều mà F8BET.COM luôn hướng đến, phát triển với chữ “Tín” đi đầu, chắc
                                            chắn sẽ mang đến cho các game thủ...
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={phonef8} alt="phonef8" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex flex-col-reverse lg:flex-row justify-center items-center pt-5'>
                                <div className='w-full lg:w-[50%]'>
                                    <div className='flex justify-between items-center gap-4'>
                                        <div>
                                            <img src={logohi88} alt="logohi88" />
                                        </div>
                                        <div className='qr px-5 md:px-0'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 px-2 md:px-10'>
                                                <img src={qrhi88} alt="qrhi88" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>SCAN MÃ QR ĐỂ TẢI APP</p>
                                            </div>
                                        </div>
                                        <div className='link'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 py-[30px] md:py-[68px] lg:py-[42px] xl:py-[62px]'>
                                                <img src={linkhi88} alt="linkhi88" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>TRUY CẬP LINK VÀO WEB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-[#535353] text-[16.5px] font-normal text-justify pt-[38px]'>
                                        <p>
                                            <b>Hi88.com</b> – là công ty cá cược uy tín thuộc tập đoàn M.A.N Entertainment,
                                            có trụ sở chính tại Philippines, và được cấp phép hợp pháp thông qua Khu kinh
                                            tế Isle of Man & Cagayan Economic Zone and Free Port. Hiện nay, Nhà cái cá cược
                                            đang phủ sóng trên khắp thị trường cá cược trực tuyến không thể nào không kể
                                            đến cái tên Hi88.com . Việc mang đến cho người chơi những phút giây thư giãn và
                                            vui vẻ chính là điều mà Hi88.com luôn hướng đến, phát triển với chữ “Tín” đi đầu,
                                            chắc chắn sẽ mang đến cho các game thủ những...
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={phonehi88} alt="phonehi88" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex flex-col-reverse lg:flex-row justify-center items-center pt-5'>
                                <div className='w-full lg:w-[50%]'>
                                    <div className='flex justify-between items-center gap-4'>
                                        <div>
                                            <img src={logomocbai} alt="logomocbai" />
                                        </div>
                                        <div className='qr px-5 md:px-0'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 px-2 md:px-10'>
                                                <img src={qrmocbai} alt="qrmocbai" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>SCAN MÃ QR ĐỂ TẢI APP</p>
                                            </div>
                                        </div>
                                        <div className='link'>
                                            <div className='border-b-[3px] boder-[#fff] p-2 md:p-5 py-[30px] md:py-[68px] lg:py-[42px] xl:py-[62px]'>
                                                <img src={linkmocbai} alt="linkmocbai" />
                                            </div>
                                            <div className='py-5'>
                                                <p className='text-[#515151] text-[11px] md:text-[15px] lg:text-[9px] xl:text-[15px] font-normal'>TRUY CẬP LINK VÀO WEB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-[#535353] text-[16.5px] font-normal text-justify pt-[38px]'>
                                        <p>
                                            <b>Mocbai.com</b> – là Một Nhà Cái Có Giấy Phép Cá Cược Trực Tuyến Hợp Pháp
                                            Do Isle Of Man Và Khu Kinh Tế Cagayan And Freeport Cấp. Với Bề Dày Kinh Nghiệm
                                            Và Danh Tiếng Phục Vụ Hơn 10 Triệu Người Chơi,Mocbai.Com Đã Và Đang Khẳng Định
                                            Vị Thế Của Mình Trên Thị Trường Game Trực Tuyến .Với Tưcách Là Một Công Ty Trò
                                            Chơi Trực Tuyến Trực Thuộc Tổng Bộ Liên Minh OKVIP Có Trụ Sở Tại Luân Đôn, Anh,
                                            Sở Hữu Đội Ngũ Nhân Tài Chuyên Nghiệp Đông Đảo Cung Cấp Sản Phẩm Phục Vụ Chất Lượng Cao
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src={phonemocbai} alt="phonemocbai" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div data-aos="fade-down" className='text-center'>
                    <h5 className='text-[25px] md:text-[30px] lg:text-[40px] text-[#f90] font-normal'>QUY TRÌNH</h5>
                    <h6 className='text-[25px] md:text-[30px] lg:text-[40px] text-[#000] font-semibold'>PHỎNG VẤN VÀ NHẬN VIỆC</h6>
                </div>
                <div data-aos="zoom-in" className='py-5 gap-2'>
                    <Slider  {...settings}>
                        <div>
                            <div className='px-2'>
                                <div className='relative'>
                                    <img src={b1} alt="buoc1" />
                                    <h5 className='text-[#fff] w-full text-center text-[17px] font-semibold absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2'>Bước 1</h5>
                                    <h6 className='text-[#FFA91E] w-full text-center text-[15px] md:text-[20px] lg:text-[17px] xl:text-[20px] font-semibold absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>Tiếp nhận thông tin</h6>
                                </div>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='relative'>
                                <img src={b2} alt="buoc2" />
                                <h5 className='text-[#fff] w-full text-center text-[17px] font-semibold absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2'>Bước 2</h5>
                                <h6 className='text-[#FFA91E] w-[90%] mx-auto text-center text-[215x]  md:text-[20px] lg:text-[17px] xl:text-[20px] font-semibold absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-0'>Sắp xếp phỏng vấn nhanh</h6>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='relative'>
                                <img src={b3} alt="buoc3" />
                                <h5 className='text-[#fff] w-full text-center text-[17px] font-semibold absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2'>Bước 3</h5>
                                <h6 className='text-[#FFA91E] w-[80%] mx-auto text-center text-[15px] md:text-[20px] lg:text-[17px] xl:text-[20px] font-semibold absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-0'>Làm thủ tục qua
                                    công ty</h6>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='relative'>
                                <img src={b4} alt="buoc4" />
                                <h5 className='text-[#fff] w-full text-center text-[17px] font-semibold absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2'>Bước 4</h5>
                                <h6 className='text-[#FFA91E] w-[90%] mx-auto text-center text-[15px] md:text-[20px] lg:text-[17px] xl:text-[20px] font-semibold absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-0'>Tiến hành qua công ty OKVIP</h6>
                            </div>
                        </div>
                        <div className='px-2'>
                            <div className='relative'>
                                <img src={b5} alt="buoc5" />
                                <h5 className='text-[#fff] w-full text-center text-[17px] font-semibold absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2'>Bước 5</h5>
                                <h6 className='text-[#FFA91E] w-[90%] mx-auto text-center text-[15px] md:text-[20px] lg:text-[17px] xl:text-[20px] font-semibold absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-0'>Đưa đón và sắp xếp nhận phòng</h6>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div>
                    <div data-aos="fade-down">
                        <h5 className='text-[#000] text-[30px] md:text-[40px] font-bold text-center'>TỔNG QUAN CÔNG TY</h5>
                    </div>
                    <div className='flex justify-center lg:justify-between items-center pt-[32px]'>
                        <div className='w-full md:w-[80%] lg:w-[50%]'>
                            <div data-aos="fade-right">
                                <h4 className='text-[30px] md:text-[40px] font-bold text-[#f90] text-center lg:text-start'>CHÍNH SÁCH<b className='text-[#000]'> NHÂN SỰ</b> </h4>
                            </div>
                            <div data-aos="fade-up-right" onClick={() => { handleOpen(1) }} className='daotao flex justify-between items-center p-1 px-3 my-10 cursor-pointer'>
                                <div>
                                    <img src={daotao} alt="daotao" />
                                </div>
                                <div className='text-[18px] md:text-[29px] font-bold text-[#fff]'>
                                    <h4>ĐÀO TẠO VÀ PHÁT TRIỂN</h4>
                                </div>
                                <div>
                                    <img src={modal} alt="modal" />
                                </div>
                            </div>
                            <div data-aos="fade-up-right" className='moitruong flex justify-between items-center p-1 px-3 my-10 cursor-pointer'>
                                <div>
                                    <img src={moitruong} alt="moitruong" />
                                </div>
                                <div onClick={() => { handleOpen(2) }} className='text-[18px] md:text-[29px] font-bold text-[#fff]'>
                                    <h4>MÔI TRƯỜNG LÀM VIỆC</h4>
                                </div>
                                <div>
                                    <img src={modal} alt="modal" />
                                </div>
                            </div>
                            <div data-aos="fade-up-right" onClick={() => { handleOpen(3) }} className='chinhsach flex justify-between items-center p-1 px-3 my-10 cursor-pointer'>
                                <div>
                                    <img src={chinhsach} alt="daotao" />
                                </div>
                                <div className='text-[18px] md:text-[29px] font-bold text-[#fff]'>
                                    <h4>CHÍNH SÁCH PHÚC LỢI</h4>
                                </div>
                                <div>
                                    <img src={modal} alt="modal" />
                                </div>
                            </div>
                            <div data-aos="fade-up-right" className='uytin flex justify-between items-center p-1 px-3 my-10 cursor-pointer'>
                                <div>
                                    <img src={uytin} alt="daotao" />
                                </div>
                                <div onClick={() => { handleOpen(4) }} className='text-[18px] md:text-[29px] font-bold text-[#fff]'>
                                    <h4>ĐÀO TẠO VÀ PHÁT TRIỂN</h4>
                                </div>
                                <div>
                                    <img src={modal} alt="modal" />
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up-left" className='hidden lg:block'>
                            <img src={girl} alt="girl" />
                        </div>
                    </div>
                    <div data-aos="fade-down" className='daoli text-center text-[#fff] py-5'>
                        <h4 className='text-[20px] md:text-[30px] lg:text-[52px] font-bold '>ĐẾN VỚI NHAU LÀ MỘT SỰ KHỞI ĐẦU</h4>
                        <p className='text-[15px] md:text-[20px] lg:text-[30px] font-bold pt-[18px]'>LÀM VIỆC VỚI NHAU LÀ SỰ TIẾN BỘ <br />
                            GIỮ ĐƯỢC CHÂN NHAU LÀ THÀNH CÔNG</p>
                    </div>
                </div>

                {/* modal */}
                <Dialog
                    open={open}
                    animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0.9, y: -100 },
                    }}
                >
                    <DialogHeader>{modalTitle || ''}</DialogHeader>
                    <DialogBody divider className=''>
                        {modalCotent || ''}
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={() => { setOpen(false) }}
                            className="mr-1"
                        >
                            <span>Đóng</span>
                        </Button>
                    </DialogFooter>
                </Dialog>
            </div>

        </div >
    )
}

export default Card