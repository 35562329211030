import React, { useEffect, useState } from 'react';
import { Button, Space, message, Popconfirm, Form, Input, Modal, Collapse, } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FaUserGraduate, FaUserSecret, FaGithubAlt, } from "react-icons/fa6";
import { BsTelegram, BsFacebook } from "react-icons/bs";
import { SiZalo } from "react-icons/si";
import { AiFillYoutube } from "react-icons/ai";
import { BiLogoTiktok, BiLogoGmail } from "react-icons/bi";
import { CgNametag } from "react-icons/cg";
import './style.css'
import { getPaging, insert, uploadImage, update, deleteUsers } from '../../../services/user';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import DataTable from './DataTable';

const imageDomain = process.env.REACT_APP_IMAGE_DOMAIN;

const { Search } = Input;
const cancel = (e) => {
    console.log(e);
    message.error('Bạn đã hủy');
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const Users = () => {
    const [usersData, setUsersData] = useState();

    const [image, setImage] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [imageEditUrl, setImageEditUrl] = useState();
    const [form] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [totalPages, setTotalPages] = useState(1);

    //pagination
    const [pageIndex, setPageIndex] = useState(1);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        getPagingUsers({ pageIndex: pageIndex, pageSize: pageSize });
        // eslint-disable-next-line
    }, []);

    const getPagingUsers = async (data) => {
        setLoading(true);
        setTimeout(async () => {
            const res = await getPaging(data);
            setTotalPages(res.totalPages * res.pageSize);
            setUsersData(res);
            setLoading(false);
        }, 500);
    }

    const onPageChange = (page, page_size) => {
        setPageIndex(page);
        setPageSize(page_size);
        onPageChangeAtSearch(page, page_size);
    }

    const onPageChangeAtSearch = async (indexPage, page_size) => {
        const params = {
            pageIndex: indexPage,
            pageSize: page_size,
        };
        getPagingUsers(params, indexPage);
    }

    const columns = [
        {
            title: 'Ảnh',
            render: (_, record) => {
                if (record.avatar) {
                    return (
                        <>
                            <div className='w-[100px]'>
                                <img className='w-full h-[10vh] object-fill rounded-[50%]' src={`${imageDomain}${record.avatar}`} alt='avatar' />
                            </div>
                        </>
                    )
                }
            }
        },
        {
            title: 'LinkWeb',
            render: (_, record) => {
                if (record.userCode) {
                    return (
                        <>
                            <div className='underline text-[#007fff]'>
                                <a href={`https://okvip.info/${record.userCode}`} target='_blank' rel="noreferrer">{record.userCode}</a>
                            </div>
                        </>
                    )
                }
            }
        },
        {
            title: 'FullName',
            dataIndex: 'fullName',
        },
        {
            title: 'SubName',
            dataIndex: 'subName',
        },
        {
            title: 'Bio',
            dataIndex: 'bio',
        },
        {
            title: 'Telegram',
            render: (_, record) => {
                if (record.telegramName && record.telegramLink) {
                    return (
                        <>
                            <div>
                                <a href={` ${record.telegramLink}`} target='_blank' rel="noreferrer">{record.telegramName}</a>
                            </div>
                        </>
                    )
                }
            },
        },
        {
            title: 'Zalo',
            dataIndex: 'zaloName',
        },
        {
            title: 'Facebook',
            render: (_, record) => {
                if (record.facebookName && record.facebookLink) {
                    return (
                        <>
                            <div>
                                <a href={` ${record.facebookLink}`} target='_blank' rel="noreferrer">{record.facebookName}</a>
                            </div>
                        </>
                    )
                }
            },
        },
        {
            title: 'Youtube',
            render: (_, record) => {
                if (record.youtubeName && record.youtubeLink) {
                    return (
                        <>
                            <div>
                                <a href={` ${record.youtubeLink}`} target='_blank' rel="noreferrer">{record.youtubeName}</a>
                            </div>
                        </>
                    )
                }
            },
        },
        {
            title: 'Tiktok',
            render: (_, record) => {
                if (record.tiktokName && record.tiktokLink) {
                    return (
                        <>
                            <div>
                                <a href={` ${record.tiktokLink}`} target='_blank' rel="noreferrer">{record.tiktokName}</a>
                            </div>
                        </>
                    )
                }
            },
        },
        {
            title: 'Gmail',
            render: (_, record) => {
                if (record.gmailName && record.gmailLink) {
                    return (
                        <>
                            <div>
                                <a href={` ${record.gmailLink}`} target='_blank' rel="noreferrer">{record.gmailName}</a>
                            </div>
                        </>
                    )
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size="middle" className='gap-2'>
                    <button onClick={() => showModal(record._id)} className='bg-[#007fff] text-[#fff] w-[30px] h-[30px] rounded-[50%]'><EditOutlined /></button>
                    <Popconfirm
                        title="Xóa nhân viên"
                        description="Bạn có thật sự muốn xóa Nhân viên này?"
                        onConfirm={() => deleteUser(record._id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        okType=''
                    >
                        <button className='bg-[#f15757] text-[#fff] w-[30px] h-[30px] rounded-[50%]'><DeleteOutlined /></button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (id) => {
        setIsModalOpen(true);
        const dataEdit = usersData?.data?.filter((item) => item._id === id);
        console.log(id)
        formEdit.setFieldsValue({
            id: dataEdit[0]._id,
            mahr: dataEdit[0].userCode,
            avatar: dataEdit[0].avatar ? dataEdit[0].avatar : "",
            fullname: dataEdit[0].fullName,
            subname: dataEdit[0].subName,
            bio: dataEdit[0].bio,
            telegram: dataEdit[0].telegramName,
            urltelegarm: dataEdit[0].telegramLink,
            zalo: dataEdit[0].zaloName,
            urlzalo: dataEdit[0].zaloLink,
            facebook: dataEdit[0].facebookName,
            urlfacebook: dataEdit[0].facebookLink,
            youtube: dataEdit[0].youtubeName,
            urlyoutube: dataEdit[0].youtubeLink,
            tiktok: dataEdit[0].tiktokName,
            urltiktok: dataEdit[0].tiktokLink,
            gmail: dataEdit[0].gmailName,
            urlgmail: dataEdit[0].gmailLink,
        });

        setImageEditUrl(`${process.env.REACT_APP_IMAGE_DOMAIN}${dataEdit[0]?.avatar}`)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (info, isEdit = false) => {
        if (info.file.status === 'uploading') {
            setLoading(false);
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                isEdit ? setImageEditUrl(url) : setImageUrl(url);
            });
            setImage(info.file.originFileObj)
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const onFinish = async (data) => {
        const dataReq = {
            userCode: data.mahr,
            fullName: data.fullname,
            subName: data.subname,
            bio: data.bio,
            telegramName: data.telegram,
            telegramLink: data.urltelegarm,
            zaloName: data.zalo,
            zaloLink: data.urlzalo,
            facebookName: data.facebook,
            facebookLink: data.urlfacebook,
            youtubeName: data.youtube,
            youtubeLink: data.urlyoutube,
            tiktokName: data.tiktok,
            tiktokLink: data.urltiktok,
            gmailName: data.gmail,
            gmailLink: data.urlgmail,
        };

        if (image) {
            const formData = new FormData();
            formData.append("image", image);
            const responseImage = await uploadImage(formData);
            dataReq.avatar = responseImage.data
        }

        try {
            if (data.id) {
                dataReq.id = data.id;

                const resUser = await update(dataReq);

                if (resUser.status === 200) {
                    message.success("Cập nhật thành công ");
                    const res = await getPagingUsers({ pageIndex: 1, pageSize: 10 });
                    setUsersData(res);
                    setIsModalOpen(false)
                } else {
                    message.error("Cập nhật thất bại !!!")
                }

                form.resetFields();
            } else {
                const resUser = await insert(dataReq);

                if (resUser.status === 200) {
                    message.success("Thêm người dùng thành công ");
                    const res = await getPagingUsers({ pageIndex: 1, pageSize: 10 });
                    setUsersData(res);
                } else {
                    message.error("Người dùng đã tồn tại !!!")
                }
                form.resetFields();
            }

            setImageUrl("")
        } catch (error) {
            message.error("Tên LinkWeb đã được sử dụng !!!")
            console.log(error)
        }
    }

    const customUploadRequest = async () => {
        message.success("upload hình thành công")
    };

    const deleteUser = async (id) => {
        try {
            const resUseres = await deleteUsers(id);

            if (resUseres.status === 200) {
                message.success("Xóa người dùng thành công ");
                const res = await getPaging({ pageIndex: 1, pageSize: 10 });
                setUsersData(res);
            } else {
                message.error("Xóa người dùng đã tồn tại !!!")
            }

            form.resetFields();
        } catch (error) {
            message.error("Xóa dùng đã tồn tại !!!")
            console.log(error)
        }
    }

    const onSearch = (seachFullName) => {
        const params = {
            pageIndex: 1,
            pageSize: 10,
            fullName: seachFullName ? seachFullName : "",
        };
        getPagingUsers(params);
    };

    const items = [
        {
            key: '1',
            label: 'Thêm Nhân Viên',
            children: <div>
                <Form
                    className='grid grid-cols-4'
                    form={form}
                    onFinish={onFinish}
                    name="myForm"
                >
                    {/* Ảnh */}
                    <Form.Item
                        name="image"
                        rules={[
                            {
                                required: true,
                                message: 'Không đăng ảnh là không có ảnh đại diện ráng chịu :)!!',
                            },
                        ]}
                    >
                        <Upload
                            name="avatar"
                            listType="picture-circle"
                            className="avatar-uploader"
                            showUploadList={false}
                            fileList={[]}
                            customRequest={(option) => customUploadRequest(option, 'avatar')}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        height: "100%",
                                        borderRadius: "50%",
                                        objectFit: "contain"
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Form.Item>
                    {/* Mã HR */}
                    <Form.Item
                        label="Mã HR"
                        name="mahr"
                        rules={[
                            {
                                required: true,
                                message: 'Làm ơn đừng để trống Mã HR!!',
                            },
                            {
                                pattern: /^(\S+)$/,
                                message: 'Mã HR không được chứa khoảng trắng.',
                            },
                            // {
                            //     pattern: /^[a-zA-Z\b]+$/,
                            //     message: 'Mã HR không được chứa số hoặc dấu',
                            // },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<CgNametag />} />
                    </Form.Item>
                    {/* fullname */}
                    <Form.Item
                        label="FullName"
                        name="fullname"
                        rules={[
                            {
                                required: true,
                                message: 'Làm ơn đừng để trống FullName!!',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<FaUserGraduate />} />
                    </Form.Item>
                    {/* subname */}
                    <Form.Item
                        label="SubName"
                        name="subname"
                        rules={[
                            {
                                required: true,
                                message: 'Làm ơn đừng để trống SubName!!',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<FaUserSecret />} />
                    </Form.Item>
                    {/* Bio */}
                    <Form.Item
                        label="Bio"
                        name="bio"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có bio đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<FaGithubAlt />} />
                    </Form.Item>
                    {/* Telegram */}
                    <Form.Item
                        label="Telegram"
                        name="telegram"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có telegram đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BsTelegram />} />
                    </Form.Item>
                    {/* linkTele */}
                    <Form.Item
                        name="urltelegarm"
                        label="URLTelegram"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link telegram đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true },
                            { type: 'string', min: 6 }
                        ]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Zalo */}
                    <Form.Item
                        label="Zalo"
                        name="zalo"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Zalo đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<SiZalo />} />
                    </Form.Item>
                    {/* linkZalo */}
                    <Form.Item
                        name="urlzalo"
                        label="URLZalo"
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Facebook */}
                    <Form.Item
                        label="Facebook"
                        name="facebook"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Facebook đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BsFacebook />} />
                    </Form.Item>
                    {/* linkFacebook */}
                    <Form.Item
                        name="urlfacebook"
                        label="URLFacebook"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link facebook đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Youtube */}
                    <Form.Item
                        label="Youtube"
                        name="youtube"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Youtube đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<AiFillYoutube />} />
                    </Form.Item>
                    {/* linkYoutube */}
                    <Form.Item
                        name="urlyoutube"
                        label="URLYoutube"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link Youtube đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Tiktok */}
                    <Form.Item
                        label="Tiktok"
                        name="tiktok"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Tiktok đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BiLogoTiktok />} />
                    </Form.Item>
                    {/* linkTikTok */}
                    <Form.Item
                        name="urltiktok"
                        label="URLTikTok"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link Tiktok đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Gmail */}
                    <Form.Item
                        label="Gmail"
                        name="gmail"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Gmail đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BiLogoGmail />} />
                    </Form.Item>
                    {/* linkTikTok */}
                    <Form.Item
                        name="urlgmail"
                        label="URLGmail"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link Gmail đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* button add */}
                    <Form.Item className='flex items-end justify-center'
                        wrapperCol={{
                            offset: 2,
                            span: 1,
                        }}
                    >
                        <Button type="default" htmlType="submit" className='bg-[#ad97e3] hover:!text-[#fff] hover:!border-[#fff]' >
                            Thêm Nhân Viên
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        },
    ];
    const onChange = (key) => {
    };
    const toggleModal = (idx, target) => {
        setIsModalOpen((p) => {
            p[idx] = target;
            return [...p];
        });
    };
    return (
        <div>
            <div style={{ marginBottom: 16, }}>
                <div className='absolute left-[400px] flex items-center'>
                    <Form form={formSearch}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Search
                                className='w-[400px]'
                                placeholder="input search text"
                                size='large'
                                allowClear
                                enterButton
                                onSearch={onSearch} />
                        </Form.Item>
                    </Form>
                </div>
                <Collapse items={items} onChange={onChange} />
            </div>
            <DataTable
                usersData={usersData?.data}
                pageSize={pageSize}
                columns={columns}
                indexPage={pageIndex}
                totalPage={totalPages}
                onPageChange={onPageChange}
                loading={loading}
            />

            <Modal forceRender title="CHỈNH SỬA THÔNG TIN" open={isModalOpen} onCancel={handleCancel} width={1200} onOk={() => toggleModal(1, false)} footer="">
                <Form className='grid grid-cols-3'
                    form={formEdit}
                    onFinish={onFinish}
                    initialValues={{
                    }}
                >
                    {/* Ảnh */}
                    <Form.Item
                        name="image"
                        label="Hình ảnh"

                    >
                        <Upload
                            name="avatar"
                            listType="picture-circle"
                            className="avatar-uploader"
                            showUploadList={false}
                            fileList={[]}
                            customRequest={(option) => customUploadRequest(option, 'avatar')}
                            beforeUpload={beforeUpload}
                            onChange={(e) => handleChange(e, true)}
                        >
                            {imageEditUrl ? (
                                <img
                                    src={imageEditUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        height: "100%",
                                        borderRadius: "50%",
                                        objectFit: "contain"
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Form.Item>
                    <Form.Item hidden={true} name="id" label="Id">
                        <Input name="id" />
                    </Form.Item>
                    <Form.Item
                        label="Mã HR"
                        name="mahr"
                        rules={[
                            {
                                required: true,
                                message: 'Làm ơn đừng để trống Mã HR!!',
                            },
                            {
                                pattern: /^(\S+)$/,
                                message: 'Mã HR không được chứa khoảng trắng.',
                            },
                            // {
                            //     pattern: /^[a-zA-Z\b]+$/,
                            //     message: 'Mã HR không được chứa số hoặc dấu',
                            // },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<FaUserGraduate />} />
                    </Form.Item>
                    {/* fullname */}
                    <Form.Item
                        label="FullName"
                        name="fullname"
                        rules={[
                            {
                                required: true,
                                message: 'Làm ơn đừng để trống FullName!!',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<FaUserGraduate />} />
                    </Form.Item>
                    {/* subname */}
                    <Form.Item
                        label="SubName"
                        name="subname"
                        rules={[
                            {
                                required: true,
                                message: 'Làm ơn đừng để trống SubName!!',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<FaUserSecret />} />
                    </Form.Item>
                    {/* Bio */}
                    <Form.Item
                        label="Bio"
                        name="bio"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có bio đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<FaGithubAlt />} />
                    </Form.Item>
                    {/* Telegram */}
                    <Form.Item
                        label="Telegram"
                        name="telegram"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có telegram đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BsTelegram />} />
                    </Form.Item>
                    {/* linkTele */}
                    <Form.Item
                        name="urltelegarm"
                        label="URLTelegram"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link telegram đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true },
                            { type: 'string', min: 6 }
                        ]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Zalo */}
                    <Form.Item
                        label="Zalo"
                        name="zalo"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Zalo đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<SiZalo />} />
                    </Form.Item>
                    {/* linkZalo */}
                    <Form.Item
                        name="urlzalo"
                        label="URLZalo"
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Facebook */}
                    <Form.Item
                        label="Facebook"
                        name="facebook"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Facebook đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BsFacebook />} />
                    </Form.Item>
                    {/* linkFacebook */}
                    <Form.Item
                        name="urlfacebook"
                        label="URLFacebook"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link facebook đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Youtube */}
                    <Form.Item
                        label="Youtube"
                        name="youtube"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Youtube đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<AiFillYoutube />} />
                    </Form.Item>
                    {/* linkYoutube */}
                    <Form.Item
                        name="urlyoutube"
                        label="URLYoutube"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link Youtube đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Tiktok */}
                    <Form.Item
                        label="Tiktok"
                        name="tiktok"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Tiktok đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BiLogoTiktok />} />
                    </Form.Item>
                    {/* linkTikTok */}
                    <Form.Item
                        name="urltiktok"
                        label="URLTikTok"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link Tiktok đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>
                    {/* Gmail */}
                    <Form.Item
                        label="Gmail"
                        name="gmail"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có Gmail đấy nhé :)',
                            },
                        ]}
                    >
                        <Input style={{ width: 360 }} prefix={<BiLogoGmail />} />
                    </Form.Item>
                    {/* linkTikTok */}
                    <Form.Item
                        name="urlgmail"
                        label="URLGmail"
                        rules={[
                            {
                                required: true,
                                message: 'Không nhập sẽ không có link Gmail đâu nhé :)',
                            },
                            { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                    >
                        <Input style={{ width: 360 }} addonBefore="http://" />
                    </Form.Item>

                    <Form.Item className=' flex items-end'
                        wrapperCol={{
                            offset: 2,
                            span: 1,
                        }}
                    >
                        <Button type="default" htmlType="submit" className='bg-[#ad97e3] hover:!text-[#fff] hover:!border-[#fff]'>
                            Cập Nhật
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default Users;