export const modalData = [
    {
        order: 1,
        title: `ĐÀO TẠO VÀ PHÁT TRIỂN`,
        content: `Nhằm giúp các nhân sự ngày càng phát triển bản thân, phát huy tối đa các tiềm năng của bản thân và tìm kiếm người lãnh đạo tiềm năng. Tập Đoàn OKVIP luôn chú trọng đến vấn đề đào tạo và nâng cao nghiệp vụ cho các cán bộ nhân viên.`
    },
    {
        order: 2,
        title: `MÔI TRƯỜNG LÀM VIỆC`,
        content: `“ CHUYÊN NGHIỆP – HIỆU QUẢ – TIẾN BỘ – QUY PHẠM ”. “ LÀM HẾT SỨC – QUẨY HẾT MÌNH “. Nhân viên làm việc bên Campuchia có môi trường tự do, thoải mãi không cấm túc, được tự do ra vào sau giờ làm việc.`
    },
    {
        order: 3,
        title: `CHÍNH SÁCH PHÚC LỢI`,
        content: `Chế độ xem xét lương theo hiệu quả công việc và hiệu quả hoạt động của tập đoàn.
                    Trợ cấp sinh hoạt mỗi tháng lên tới 3.000.000 VND.
                    Thưởng sinh nhật nhân viên 2.000.000 VNĐ.
                    Lễ tết 10.000.000 VNĐ
                    Mỗi 6 tháng làm việc có 15 ngày nghỉ phép về nước có lương.
                    Mỗi 6 tháng làm việc có 15 ngày nghỉ phép về nước có lương.`
    },
    {
        order: 4,
        title: `UY TÍN HÀNG ĐẦU`,
        content: `Để có sự thành công và phát triển như ngày hôm nay. OKVIP luôn lấy Uy tín là kim chỉ nam hàng đầu cho sự phát triển.`
    }
]