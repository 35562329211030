import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { TOKEN_NAME } from '../utilities/constants';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem(TOKEN_NAME);
    let location = useLocation();

    if (!token) {
        return <Navigate to="/admin/login" state={{ path: location.pathname }} replace />
    }
    return children
};

export default ProtectedRoute;