import React from 'react'
import logo from "../../../images/logo.png"

const Header = () => {
  return (
    <div>
      <section className="bg-[#1A1A1A] px-2">
        <div className="max-w-[1200px] mx-auto flex justify-center items-center lg:justify-start">
          <a className="w-[35%] md:w-[20%] lg:w-[15%]  py-5" href="/#">
            <img className="w-full" src={logo} alt="logo" />
          </a>
        </div>
      </section>
    </div>
  )
}

export default Header