import React from 'react'
import Caursel from '../Caursel';
import Profile from '../Profile';
import Card from '../Content';

const Hr = ({ hrData }) => {
  return (
    <div>
      <div className='overflow-hidden'>
        <Profile hrData={hrData} />
        <Caursel />
        <Card />
      </div>
    </div>
  )
}

export default Hr;