import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hr from "../../components/Hr";
import { getByUserCode } from "../../services/user";

const Home = () => {
    const [hrData, setHrData] = useState();
    const { userCode } = useParams();

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, []);

    const fetchData = async () => {
        const res = await getByUserCode({
            userCode: userCode
        });
        setHrData(res)
    }

    return (
        <>
            {
                hrData?._id ? (<Hr hrData={hrData} />) : (
                    <div>
                        <div className="absolute top-0 left-0 w-full h-full z-[9999]">
                            <video style={{ maxWidth: "100%" }} className="object-fill w-full h-full" loop autoPlay muted>
                                <source src="https://okvip.cash/images/okvip/20230809161848_508019.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Home